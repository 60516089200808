import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

import { registerPage as pageContent } from "../../components/constants/index";

import "./registration.css";

const Register = (props) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    name: "",
    type: "",
    phone: "",
    street: "",
    city: "Lahore",
    zipcode: "",
    fullname: "",
    email: "",
    description: "",
    weekday_open_time: "",
    weekday_close_time: "",
    weekend_open_time: "",
    weekend_close_time: "",
    cover_photo_file: "",
    logo_file: "",
    menu_file: "",
  });

  const enableSubmit = () =>
    payload.name &&
    payload.email &&
    payload.phone &&
    payload.menu_file &&
    !loading;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "menu_file" ||
      name === "logo_file" ||
      name === "cover_photo_file"
    ) {
      setPayload({ ...payload, [name]: e.target.files[0] });
    } else {
      setPayload({ ...payload, [name]: value });
    }
  };

  const handleSubmitRegister = (event) => {
    console.log("handleSubmitRegister", payload);

    const formData = new FormData();

    formData.set("name", payload.name);
    formData.set("phone", payload.phone);
    formData.set("email", payload.email);
    formData.set("menu_file", payload.menu_file);

    setLoading(true);
    axios
      .post("https://api.flavry.com/api/branch/v1/erestaurant/send/", formData)
      .then((res) => {
        toast.success(
          "We have received you request and we will contact you soon."
        );
      })
      .catch((err) => toast.error("Something went wrong. Please try again."))
      .finally(() => setLoading(false));
  };

  const {
    name,
    type,
    phone,
    street,
    city,
    zipcode,
    fullname,
    email,
    description,
    weekday_open_time,
    weekday_close_time,
    weekend_open_time,
    weekend_close_time,
  } = payload;

  return (
    <div className="page-body">
      <section className="heading-cover">
        <div className="container heading-container">
          <h1>{pageContent.heading}</h1>
        </div>
      </section>

      <section className="mt-5">
        <div className="container ">
          <div className="row mt-5">
            <div className="col-12">
              <div className="page-text-area">
                <p>
                  Enter all the information of your restaurant or food business
                  that explains what the restaurant food business is about.
                  Fields with <span style={{ color: "red" }}>*</span> are
                  necessary
                </p>

                <form>
                  <div className="col-12">
                    <h3>Restaurant Information</h3>

                    <div className="row mt-3">
                      <div className="col-lg-3 col-md-5 col-sm-12">
                        <label>Name of restaurant</label>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-10">
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Type of restaurant</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      name="type"*/}
                    {/*      value={type}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="row mt-3">
                      <div className="col-lg-3 col-md-5 col-sm-12">
                        <label>Restaurant’s phone number</label>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-10">
                        <input
                          type="tel"
                          pattern="\+92[0-9]{10}"
                          name="phone"
                          value={phone}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <span className="instruction">
                          Format: +923455517135
                        </span>
                      </div>
                    </div>

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Restaurant’s official address</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <textarea*/}
                    {/*      value={street}*/}
                    {/*      name="street"*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*      type="text"*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>City</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      name="city"*/}
                    {/*      value={city}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Post code</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      name="zipcode"*/}
                    {/*      value={zipcode}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="row mt-3">
                      <div className="col-lg-3 col-md-5 col-sm-12">
                        <label>E-mail address</label>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-10">
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>About Restaurant</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <textarea*/}
                    {/*      name="description"*/}
                    {/*      value={description}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*      type="text"*/}
                    {/*    >*/}
                    {/*      {description}*/}
                    {/*    </textarea>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Weekdays opening time</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      name="weekday_open_time"*/}
                    {/*      value={weekday_open_time}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Weekdays closing time</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      name="weekday_close_time"*/}
                    {/*      value={weekday_close_time}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Weekend opening time</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      name="weekend_open_time"*/}
                    {/*      value={weekend_open_time}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Weekend closing time</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      name="weekend_close_time"*/}
                    {/*      value={weekend_close_time}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Your name</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="text"*/}
                    {/*      name="fullname"*/}
                    {/*      value={fullname}*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="row mt-3">
                      <div className="mt-3 col-lg-3 col-md-5 col-sm-12">
                        <label>Upload your menu</label>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-10">
                        <input
                          type="file"
                          name="menu_file"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="mt-3 col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Upload your logo</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="file"*/}
                    {/*      name="logo_file"*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-lg-3 col-md-5 col-sm-12">*/}
                    {/*    <label>Upload your cover image</label>*/}
                    {/*  </div>*/}
                    {/*  <div className="col-lg-5 col-md-5 col-sm-10">*/}
                    {/*    <input*/}
                    {/*      type="file"*/}
                    {/*      name="cover_photo_file"*/}
                    {/*      onChange={(e) => handleChange(e)}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="row mt-3">
                      <div className="col-lg-3 col-md-5 col-sm-12" />

                      <div className="col-lg-5 col-md-5 col-sm-10 mb-4 text-center mt-3 spinnerContainer">
                        <div className="spinner">
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={loading}
                          />
                        </div>
                        <button
                          className="btn site-btns"
                          type="button"
                          onClick={handleSubmitRegister}
                          disabled={!enableSubmit()}
                        >
                          Create eRestaurant
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
