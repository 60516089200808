import React from "react";
import ServicesCards from "./services-cards";
import forpartnerSvg from "../../assets/images/for-restaurants.svg";
import Burger from "../../assets/images/Burger.png";
import ContactUsArea from "./ContactUsArea";
import AboutUsArea from "./AboutUsArea";
import SignupSteps from "./SignupSteps";
import { forPartner } from "../../components/constants/index";
import ScrollAnimation from "react-animate-on-scroll";

const ForPartner = () => {
  return (
    <div className="page-body">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-9 col-xs-12">
            <img className="image100" src={forpartnerSvg} alt="" />
          </div>
        </div>
      </div>

      <section className="heading-cover">
        <div className="container heading-container">
          <h1>{forPartner.heading}</h1>
        </div>
      </section>

      <section className="partner-content-area">
        <div className="container">
          <div className="col-12 page-content">
            <div className="text-area mt-4">
              <p>{forPartner.content}</p>
            </div>
          </div>

          <div>
            <ul>
              <li>
                <b>
                  Empowering individuals to start their own online restaurant:
                </b>{" "}
                The homemade food discovery feature empowers individuals,
                especially housewives or those who cannot go out and earn, to
                start their own online restaurant, providing them with a source
                of income.
              </li>
              <li>
                <b>Taking a normal restaurant online:</b> Flavry assist
                businesses to take their restaurants online by providing
                features like online ordering, table reservations, and digitized
                menus.
              </li>
              <li>
                <b>Creating a dedicated web page:</b> By using Flavry businesses
                can have a dedicated web page where they can advertise about
                their services, facilities, opening and closing times, and
                discount offers.
              </li>
              <li>
                <b>Managing billing and deals/discounts:</b> Flavry's simple and
                easy-to-use dashboard helps businesses manage billing and
                specific deals and discounts for their customers.
              </li>
              <li>
                <b>Listing dishes on the Flavry marketplace:</b> The Flavry
                discovery feature enables businesses to list their dishes on the
                Flavry marketplace, giving them more visibility and generating
                more online orders.
              </li>
            </ul>
          </div>

          <ServicesCards />
        </div>
      </section>
      <section className="mt-5">
        <div className="orange-bar burgerbar" />
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="sampleStepsheading">
                {forPartner.stepsHeading}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <ScrollAnimation
                animateOnce="true"
                delay={600}
                animateIn="fadeIn"
              >
                <img
                  className="burgerImg"
                  width="300"
                  src={Burger}
                  alt="Burger"
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <SignupSteps />
      <AboutUsArea />
      <ContactUsArea />
    </div>
  );
};

export default ForPartner;
