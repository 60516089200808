export const homePage = {
  headerImg: "../assets/images/about-us.svg",
  heading: "What would you like to eat?",
  content: `
  <p> Download the Flavry app and get personalized food results and more. Discovery, Pickup, Delivery, and Table Reservation, all in one App. </p>`,
  downloadApp: "Download the Flavry App",
  image: "../assets/images/mobile-screens.png",
  stepsHeading: "Start with us today with 3 simple steps!",
};

export const aboutUs = {
  headerImg: "../assets/images/about-us.svg",
  heading: "About Us",
  content: `Flavry is the smart platform that simplifies the process of discovering the wealth of flavors and foods around you, tailored to YOUR profile. Yes yours, because we believe we all have our own unique way of experiencing flavors.`,
  content1: `Our solution is a vast, up-to-date database of restaurants and their offerings, down to the ingredient level, brought together by our inhouse magicians. We also don’t just recommend, we help you get that tempting meal, whether it is via delivery, pickup or a table reservation for that special occasion.`,
  image: "../assets/images/mobile-screens.png",
};

export const forPartner = {
  headerImg: "../assets/images/about-us.svg",
  heading: "For Restaurants",
  stepsHeading: "Start with us today with 3 simple steps!",
  content: `Ready to take your restaurant to the next level? Want to convert your restaurant into an eRestaurant? By offering your dishes through our Flavry food search engine you can. With us, you will reach more customers and increase your sales, while we will handle the digital side of your pickup/food delivery orders and table reservations for dine-in customers.`,
  image: "../assets/images/mobile-screens.png",
};

export const servicesCard = {
  heading1: "Food",
  content1: `Flavry, we bring together restaurant-goers and the exquisite flavors that surround them.`,
  heading2: "Search and Exposure",
  content2: `Don’t make your customers guess what you offer, how you offer it. Give them a digital menu and list of all your services that make you unique.`,
  heading3: "Digital Experience",
  content3: `We offer an unparalleled digital experience to your customers. You get a beautiful dedicated
webpage and your customers get a universal mobile app to interact with you.`,
};

export const signUpSteps = {
  heading1: "1",
  content1: `Sign up and Upload the Menu.`,
  heading2: "2",
  content2: `We do the Screening.`,
  heading3: "3",
  content3: `That’s it! Your eRestaurant is ready to serve.`,
};

export const aboutArea = {
  heading: "About Us",
  startJourny: "Start your journey now",
  content: `<p>Flavry the data-driven platform that bridges the gap between restaurant goers and the magnificent flavors to be
  discovered.</p>
  <p>Flavry’s solution is a vast, up-to-date database of restaurants and their menus, down to the ingredient level.
  It can even recommend and order the food you would like to be based on your personal taste profile.
  </p>
  <p>Flavry’s solution is a vast, up-to-date database of restaurants and their menus, down to the ingredient level.
  It can even recommend and order the food you would like to be based on your personal taste profile.
  </p>`,
  downloadAppPage: "Download App",
  createERes: "Create eRestuarant",
};

export const contactArea = {
  heading: "Contact Us",
  homeHeading: "Let Us Take Care of your setup.",
  content: `<p>
  Still don’t know how to do it? Lets us take care of your setup.
  <small>
    (This process will take a little longer than online signup)
  </small>
</p>
`,
};

export const faqPage = {
  image: "../assets/images/mobile-screens.png",
  heading: "FAQ",
  content: `
  <h2>What is Flavry?</h2>
  <p>Flavry is a food and cuisine discovery and recommendation service that puts the discovery of food and cuisines you're interested in ahead of anything else. The pickup and delivery services are complimentary so that you may get to enjoy your discovery as well.</p>  
  <p></p>
  <h4 className="mt-4">Ordering food and delivery process</h4>
  <h5>How to order food using Flavry?</h5>
  <p>Using Flavry to order food is fairly simple thanks to the simple yet innovative app design. You can follow these simple steps to order food using Flavry:</p>
  <ol>
    <li>You can search for Dishes, Restaurants and their Menus without having a Flavry Account. After registering for Flavry you can enter your location/address in the location field and avail Delivery, Pickup and Table Reservation services.</li>
    <li>You can either select from the list of all available restaurants/dishes in your area or choose from the list of recommended meals based on your interests.</li>
    <li>Select any dish offered by the restaurant you have chosen and proceed to check out by selecting a payment method.</li>
    <li>Once your order is ready, the restaurant delivery person will deliver it to your location.</li>
    <li>Receive the food from the set location and enjoy your meal.</li>
  </ol>

<h5>Can I make changes to the food I order?</h5>
<p>You can ask a Flavry restaurant partner to add or exclude certain ingredients from the food you are ordering at the time of checkout. However in some cases it might not be possible for our partners to fulfill the special requests made by you regarding changes to the food being ordered. 
<br><b>Note: If you have any allergies linked to certain ingredients it is recommended that you inform the restaurant partner about these allergies when ordering a food.</b></p>
<h5>Why can’t I find certain restaurants on Flavry?</h5>
<p>Flavry shows you the restaurants that either deliver food or offer pickup service to your area and those that match the dishes you were searching for, thus if a restaurant is not offering delivery or pickup in your area or does not match your search terms, it will not be listed. In case you can’t find a restaurant on Flavry that was previously available it might be because the restaurant is closed.</p>

<h5>Can I place an order on behalf of someone else using Flavry?</h5>

<p>Flavry allows users to order food on behalf of their friends and loved ones. All you have to do is add the address of the recipient of food delivery at the time of checkout and our restaurant partners will deliver the food to their location.</p>

<h5>How long does food delivery take?</h5>
<p>When you place an order through Flavry you get the option to check the estimated delivery time. The amount of time it can take to deliver an order varies depending upon a number of factors; however you can view the estimated delivery time for each restaurant before placing an order to get an idea about how long food delivery can take. If there is a major delay in order delivery you will be notified about the options you have in case of  delayed delivery by our Restaurant Partner.</p>
<h5>The delivery person can’t find my address. What should I do?</h5>
<p>Restaurant delivery personnel deliver food on the location you provide at the time of order placement. If the delivery personnel can’t find your location they will contact you directly using the phone number you have provided to Flavry. By making sure you have your phone nearby you can make sure that your delivery goes smoothly.</p>

<h5>Can I cancel an order?</h5>
<p>You can only cancel an order if our restaurant partner has not yet started preparing your food. As long as your order is not being prepared you can always cancel your order.</p>
<h5>Can I change the delivery address once the order has been placed?</h5>
<p>Yes you can change the delivery address after placing your order by contacting our customer support in case the previously entered address was incorrect. However if the restaurant does not deliver in the area that you have provided in the updated delivery address we will cancel your order. 
<br><b>Note: Modifying delivery address once the order has been placed can cause changes in the delivery fee.</b></p>
<h5>Can I edit my order once it has been placed?
<p>Once the order has been placed and the restaurant has started preparing your food you can not edit your order. You can however cancel your order and place a new one in case the restaurant has not started preparing your food.</p>
<h5>How can I report a missing or incomplete order?</h5>
<p>If you receive an order that is incomplete or has something wrong with it you can always contact our customer support about the incorrect order along with the order number to receive assistance. You can also contact our partner restaurant directly as they handle food returns.</p>


<h4 className="mt-4">Payment and discounts</h4>
<h5>How to add a payment method in Flavry?</h5>
<p>The easiest way to add a payment method on Flavry is by adding a payment method at the time of order completion, before checking out. You will be provided with the payment methods available to you including Mobile Pay (Jazz Cash), debit card, or credit card when completing an order.</p>
<h5>How can I remove a payment method?</h5>
<p>That’s one more thing you do not have to worry about, we do not store your payment information. Our payment partners use your provided details only for the transaction being processed at that moment, that’s all.</p>
`,

  headerImg: "../assets/images/about-us.svg",
};

export const privacyPage = {
  headerImg: "../assets/images/about-us.svg",
  image: "../assets/images/mobile-screens.png",
  heading: "Privacy Policy",
  content: `
  <p>This privacy policy has been developed to help the users of Flavry in understanding their data protection rights. The contents of this page will also explain how we process the data we collect about our users. The data we collect about our users is used to enhance the user experience.</p>
  <h5>Data we collect about our customers</h5>  

<p><strong>Contact information:</strong> In the contact information category we collect your Name, address, phone number, email information, and your social media account information if you choose to connect it with our app. </p>
<p><strong>Location information:</strong> We collect your location data to ensure quick and accurate order processing.</p>
<p><strong>Profile data:</strong> When you create an account with us we collect profile data that is necessary to create your customer profile in our database. The profile data we collect includes your name, email address, password, phone number, address, demographic data, and interests.</p>
<p><strong>Your order history:</strong> When you place an order using our service the order details will be added to your order history. Your order history data allows us to suggest more personalized foods and restaurants to you based on your previous order. Similarly your previous dine in reservations are also saved in our database to provide you with more personalized suggestions for your future dine in reservations and to simplify if you want ro reorder a service or an order.</p>
<p><strong>Payment details:</strong> Your payment details and transaction data is not stored by us because we don’t process your financial details. Any online transactions you make using our platform will be processed and handled by our third party partners. However we store information about the payment method you choose when placing an order to allow us in tracking and refunds.</p>


<h5>How do we use your stored data?</h5>
<p>Our use of stored information about the customers is governed by privacy rules and regulations and we maintain the privacy protection standards when processing and handling user data. From collection to storage of your data each step of data collection, processing and storage is closely monitored to ensure that user data is protected. The way we ensure user data security is by collecting only the essential data about the customers and using data protection measures to prevent unauthorized access to user data. The data we collect is collected only after your permission and we don’t collect or store information not mentioned in this privacy policy.</p>
<h5>What actions can you take regarding your data that is stored with us?</h5>
<p>The personal information that we store about our customers is fully accessible to them. You can choose to take following actions regarding your data:</p>


<p><strong>Access it at any time:</strong> We ensure full disclosure and access to our users to their information that is stored with us. You can contact us at any time to view what information we have stored about you.</p>
<p><strong>Correction of stored data:</strong>If you notice that any information we have stored about you is incomplete or incorrect you have the right to get it corrected.</p> 
<p><strong>Right to erase data:</strong>As the owner of the data you can contact us if you want to get your data to be removed from our storage.</p>
<p><strong>Revoke permission to store and process data:</strong>You have the right to revoke your permission to process your information. You can also object if you think our processing of your data is incorrect but we only use your information for the purpose it was collected for. You can always get in touch with us to revoke your permissions provided to us regarding collection and storage of data.</p>

<h5>User data we share with delivery personnel</h5>
<p>We use delivery personnel that handle the safe delivery of your food to your doorstep when you place an order through Flavry. To ensure that delivery personnel can complete your delivery we have to share some information with them including location data, name, and contact information. </p>
<h5>Who do we share user data with?</h5>
<p>We only share user data with data analytics services that allow us to interpret user data and help us in improving our services. The data analytic services we share user data with may store and process the data based on their own privacy policies.</p>
<h5>How long do we store user data?</h5>
<p>We only store user data as long as it is meant to be used for. Different types of data are stored for different amounts of time however you can always contact us if you want to get your data removed before the intended storage duration. Some data that we are legally required to store as per the requirements of the legal agencies may stay in our storage to comply with legal regulations.</p>
`,
};

export const termsPage = {
  headerImg: "../assets/images/about-us.svg",
  image: "../assets/images/mobile-screens.png",
  heading: "Terms & Conditions",
  content: `
  <p>
  The terms and conditions stated on this page govern how customers/users can use the Flavry application. Please make sure to read the below mentioned terms and conditions carefully and by continuing to use Flavry you agree to these terms in their entirety. If you are not comfortable with any of the terms and conditions please avoid accessing this application. <br/>
  <b>Note: In order to continue using Flavry the user must be at least 18 years of age. Or you must be a legally operating commercial entity to use this application.</b></p>
  <p>If you are a person who is under the age of 18 you need to get consent from your parents or guardian regarding the terms and conditions of Flavry.  If your parent/guardian disagrees with the terms and conditions mentioned below you are not allowed to use this application.</p>
  <p>We may change or add to the terms and conditions of using Flavry and we suggest you to regularly read the terms and conditions in order to stay updated with any changes.</p>
  <h5>Becoming a user of Flavry</h5>

  <p>When you become a user of Flavry you will have to provide certain information as well as meet some basic criteria to be eligible to use the app. The information users must provide us in order to become a user of this app includes name, email address, phone number, and location information. </p>
  <p>Apart from providing the above mentioned information users also have to be 18 years or older in order to be eligible for using this app.</p>
    <p>When users register with our application they create a username and a password and users are responsible to keep their account information confidential. In case there is an attempt to make a transaction through your user account it will be considered your responsibility. </p>
  
  <p> If you suspect that your account details have been compromised and a third party might have access to your account you are responsible for informing us as soon as possible. Flavry may close your account if it has been compromised without any notice.</p>
  <p> When you order food or reserve a table using Flavry you are responsible for making sure that the information you provide at the time of purchase/booking is accurate. </p> 
  <h5>Product and services</h5>
  <p>Flavry allows you to order food and make reservations with restaurants available on the platform. The partner restaurants on our platform deliver the food. We handle the transaction information and forward user orders/requests/queries to our partner restaurant network. Our operations are governed by the laws of the state.
</p>

      <p><strong>Ordering food: </strong> When you are ordering any food available on Flavry you must make sure that the restaurant is located near your physical location. The restaurants and the foods available on our platform may change from time to time so make sure you stay up to date with food and restaurant availability. We may discontinue the availability of food at any time without notice based on legitimate reasons.</p>
      <p>When you are ordering a food you have the option to get it delivered as soon as possible or pick up the food yourself from a restaurant available on our platform. Your order is considered as your offer for purchasing a product available on Flavry and the fulfillment of your order is dependent on availability of the food you ordered. Moreover we may choose to decline your offer to purchase food available on our platform if necessary.</p>
       <p><strong>Supply of food: </strong>
      When you place an order through Flavry you will receive an order confirmation email and we will confirm that our restaurant has received your order information. In case the product is not available it may not be supplied to you and the order can be canceled. You are eligible for a full refund in case your order that you had already paid for gets canceled. We only accept and fulfill orders that are placed via our application and we don’t accept orders through any other platforms. When the food has been supplied and handed over to you any damage or loss of food is solely your responsibility. You are responsible for picking up food from the set location.</p>
       <p><strong>Food delivery: </strong>
      The actual physical delivery of goods purchased via Flavry are delivered by our Restaurant partners. At the time of order placement using Flavry you agree that the food will be delivered on the agreed upon location for the order. Once the order has been placed the delivery services will be charged and it is not refundable in case of change of mind or any other reason. </p>
      <p>It is your responsibility to be present at the location provided for the order delivery unless in cases when your order is to be delivered by the door. If your order is to be delivered by your door Once the order has been delivered by your door in case of contact less delivery the responsibility of the safety of food lies solely on you. If there are any restrictions for delivering the food near your door we are not responsible for issues with the delivery of the order. </p>
      <p>In case further communication is needed to fulfill the delivery you must be available to attend any calls or reply to any messages from partner’s delivery personnel. If you are not available on the provided number at the time of delivery and our delivery partner is unable to deliver the order it will be automatically canceled. </p>
      <p>If you have made a reservation using Flavry you may be required to confirm your identity before our restaurant partner provides you their services.</p>
       <p><strong>Food quality and ingredients: </strong>
      Flavry provides a complete list of ingredients along with the foods we offer through our platform. However the list of ingredients may not be accurate all the time and we suggest you get in touch with our restaurant partner regarding any allergies or ingredients that you want to avoid in your order. Our restaurant partners are monitored by relevant food authorities to ensure the quality and proper hygiene of the food being delivered. Most foods available at Flavry are meant to be consumed immediately after delivery to ensure freshness.</p>

  <h5>Pricing and cost </h5>
  <p>All the prices of items listed on Flavry along with the delivery costs will be represented in Pakistani Rupee (PKR). </p>
  <p>When you are browsing items on Flavry you can see the prices next to each item and you will see the price of items along with the delivery charges at the time of placing an order.  You can review the prices in the shopping cart before checking out and finalizing your order. However if the price quoted for an item or at the time of checkout is incorrect as a result of a technical error the incorrect price will not be applicable. Flavry reserves the right to change the prices, quantity or the information about an item listed on our platform. </p>
  <p>Flavry sets a minimum order value and it may be different for different restaurants operating on our platform. You can view the minimum order value for a restaurant in the cart and you can also view it at the time of check out. If you place an order that doesn’t meet the minimum order value limit you will be charged the difference between the minimum order value and the total price for your order. </p>
  <p>Flavry may control the prices of items listed on our platform and limit the ability of our restaurants to charge different prices for their foods on Flavry compared to their physical outlets. This helps us in making sure that our customers are paying the same price as they would if they bought directly from the outlet. </p>
    <p><strong>Special offers and vouchers: </strong> We may offer special price offers and promotional campaigns including gift cards from time to time to our valued customers. We may refer to these promotions as “Campaigns” and one user can only avail these promotions once at a time unless the promotion specifies otherwise. We may stop a campaign at any time without prior notice to users.</p>
    <p>The vouchers that we award to our users can be redeemed at the time of check out of an order. Vouchers can not be redeemed for cash and they can only be used when placing an order. Each voucher can only be used once and it has been used so it can not be reactivated or returned.</p>
     <p><strong>Payments: </strong> Payment of all the orders will have to be made when placing an order. </p>
    
    
      <h5>Data processing </h5>
      <p>Our data processing practices are governed by our privacy policy.</p>
    
     <h5>Food item errors, feedback and refund </h5>
     <p>If any food item or reservation that you receive through our service is incorrect or different from description or if it gets damaged during delivery we recommend you to contact our customer service representative. Our team will investigate your complaint on a priority basis and ensure that a solution is provided for you in the shortest period of time. We reserve the right to choose different solutions for different complaints depending upon the individual scenario of each complaint. The liability of errors in food lies solely on the restaurant you ordered the food from though Flavry. So the restaurant partners are directly responsible for fulfilling complaints and warranty errors. </p>
       <p><strong>Refund policy: </strong> You have a right to change your mind for any reason and you are eligible for asking for a refund within 14 days of receiving the delivery. However you are not eligible for a refund in the following cases:</p>
       
<ul>
<li>If the service has been provided to you in full, with your consent and on your request.
</li>
<li> Food items that have a short shelf life are not eligible for refund.</li>
<li>If the food item can not be resealed or returned due to hygiene related reasons.
</li>
<li>Foods that have been custom-made on the request of the customer.</li>
</ul>    

<p>In order to be eligible for refund you have to make sure that the package is unchanged and no seals (if required) should have been opened.</p>   
<p><strong>Note: The restaurant partners of Flavry are responsible for refunds and returns</strong></p> 



<p><strong>Refund procedure:</strong> If you want to get a refund for an order you will have to contact our support team within the 14 day refund and cancellation period. You will have to inform our representative that you want to get a refund and you must provide your name, email address, order number and other necessary information when requesting a refund.</p> 
<p><strong>Note: In case the order was not delivered to you and it was instead returned to the restaurant partner due to failure to deliver the order will be canceled.</strong></p>

<p><strong>Responsibilities of user when requesting a refund:</strong> When you are requesting a refund you are responsible for ensuring the following:</p>

<ul>
<li>Return freight cost</li>
<li>Condition of the food item</li>
<li>Return of the item within the 14 day period</li>
</ul>


<p>You will also have to handle the items with care before returning them for a refund and you will have to make sure that the item is not damaged during the return transit. The order should contain all the items that were originally delivered along with the product including labels and packaging. If you mishandled or damaged the item and the usefulness of the item has been compromised you will be responsible for compensating the damage caused to the item.</p> 
<p><strong>Approval of refunds : </strong> If your refund request gets approved you will receive the refund using the same payment method that you used for payment at the time of placing the now refunded order. We may reduce the refund amount to compensate for the loss of value of the item and the additional processing that was done on it during the refund process.</p>


<h5>Customer services</h5>

<p>If you have any queries about our platform, product, or usage you can always contact our customer support for answers.</p> 

<h5>Termination of services</h5>
<p>We may terminate the supply of our services through Flavry to customers that are found to be in violation with the terms of use of this application.</p> 

<h5>Transfer of rights</h5>
<p>In case of an acquisition or merger with another party Flavry may transfer its right to the third party only if the third party is able to fulfill the terms properly.</p>
<p>Customers of Flavry are not bound to accept the transfer of rights to other parties.</p>

<h5>Liabilities</h5> 
<p>Users of Flavry are liable to compensation to Flavry in case they cause any direct or indirect harm to the platform by going against the terms and conditions mentioned on this page.</p> 
<p>In addition to the terms stated on this page the liabilities of Flavry to customers is determined by the following:</p>
<ul>
<li>Flavry does not offer warranty for any content, material, or ingredients along with other information associated with products or services sold through Flavry platform.</li>
<li>Flavry is a standard application and we do not claim responsibility if our platform is not fit for use in your specific situation. Moreover, we do not warrant the functionality, availability, security or usefulness of our platform.</li>
<li>We are not responsible for any direct or indirect physical, financial or reputation damage related to the use of our platform.</li>
<li>We are not responsible in case of any unauthorized access, use, or modification of your data that you provided to Flavry or the information you receive from us. We do not claim responsibility for any legal action that may arise from your use of our services.</li>
<li>The terms and conditions listed on this page do not limit or eliminate your rights as a customer.</li>
</ul>

<h5>Applicable laws</h5> 
<p>These terms and conditions are governed by the law of Islamic Republic Of Pakistan. We prefer to solve any disputes relating to the Flavry platform through negotiations however if we are unable to resolve a dispute you have the right to contact the courts of Pakistan.</p> 

  `,
};

export const pressPage = {
  headerImg: "../assets/images/about-us.svg",
  image: "../assets/images/mobile-screens.png",
  heading: "Press",
  press: [
    ["Press title here", `<p>Content of the page here</p>`],
    ["Press title here", `<p>Content of the page here</p>`],
    ["Press title here", `<p>Content of the page here</p>`],
    ["Press title here", `<p>Content of the page here</p>`],
    ["Press title here", `<p>Content of the <a href="/">page</a> here</p>`],
    ["Press title here", `<p>Content of the page here</p>`],
    ["Press title here", `<p>Content of the page here</p>`],
    ["Press title here", `<p>Content of the page here</p>`],
    ["Press title here", `<p>Content of the page here</p>`],
  ],
};

export const registerPage = {
  headerImg: "../assets/images/register-and-start-selling.svg",
  heading: "Register and Start Selling",
  content: `
  <p>By becoming a part of our “Refer a Friend” program you can receive special discounts and vouchers for your future orders. You can also send discounts to your friends by referring them to the Flavry platform. </p>
`,
};

export const contactPage = {
  headerImg: "../assets/images/register-and-start-selling.svg",
  heading: "Contact Us",
  content: `Some Description Here`,
};

export const careerPage = {
  headerImg: "../assets/images/about-us.svg",
  image: "../assets/images/mobile-screens.png",
  heading: "Career",
  contentH1: ``,
  content1: `<p>We are a constantly expanding company and we are looking for talented individuals to join Flavry and be a part of our team. If you want to join Flavry make sure to drop your CV and we will get back to you as soon as possible.</p>`,
  contentH2: ``,
  content2: ``,
};

export const referFriendPage = {
  headerImg: "../assets/images/about-us.svg",
  image: "../assets/images/mobile-screens.png",
  heading: "Refer a Friend",
  content: `

  <h4>Friend referral terms and conditions</h4>
  <p>By becoming a part of our “Refer a Friend” program you can receive special discounts and vouchers for your future orders. You can also send discounts to your friends by referring them to the Flavry platform.</p>
  <h4>How do friend referrals work?</h4>
  <p> You can only use friend referrals to invite friends that are not already a part of the Flavry platform. You can invite a friend using Refer a Friend program with the help of following steps:</p>

  <ul>
  <li><p>You will have to send a unique referral link that is generated by Refer a Friend feature to a friend via email, SMS, Whatsapp or any other messaging medium.</li>
  <li><p>Your friend will have to use the referral link you sent to them to download the official Flavry application.</p></li>
  <li><p>When your friend makes their first order after downloading Flavry using your referral link your friend and you both will receive a discount voucher.</p></li>
  <li><p>The discount voucher can be redeemed at the time of checking out when placing an order.</p></li>
  <li><p>Voucher may only be applicable to orders that are worth a certain minimum amount.</p></li>
  <li><p>Some restaurants on our platform may not accept discount vouchers so the voucher will not be applicable to restaurants that don’t accept them.</p></li>
  <li><p>You can send referrals to 10 people and therefore can only receive 10 discount vouchers through the Refer a Friend program.</p></li>
  <li><p>You can view the discount vouchers you earn by referring Flavry to friends at any time.</p></li>
  <li><p>The terms and conditions that govern the use of Refer a Friend program are subjected to change at any time.</p></li>
  </ul>
  
  </p>
`,
};

export const refundPage = {
  headerImg: "../assets/images/about-us.svg",
  image: "../assets/images/mobile-screens.png",
  heading: "Refund Policy",
  content: `
  <p>You have the right to change your mind for any reason, and you can request a refund within 14 days of receiving your order. However, under the following circumstances, you will not be eligible for a refund:
</p>
   <ul>
   <li>If the service was delivered to you in its entirety with your permission and at your request.</li>
   <li>Food items that have a short shelf life are not eligible for refund.</li>
   <li>If the food item is unable to be resealed or returned owing to sanitary concerns.</li>
   <li>Foods that have been custom made on the request of the customer.</li>
  </ul>
  <p>To be eligible for a refund, you must ensure that the box is undamaged and that no seals (if applicable) have been broken. </p>

  <p><b>Note: Refunds and returns are the responsibility of Flavry's restaurant partners.</b></p>
  
`,
};

export const downloadAppPage = {
  headerImg: "../assets/images/about-us.svg",
  image: "../assets/images/mobile-screens.png",
  heading: "Download Flavry App",
  content: `
  <p>Top restaurants of your city are offering their food through Flavry. Don’t miss out the opportunity to order amazing food at your doorstep, download Flavry apps and experience the convenience.</p>`,
};
