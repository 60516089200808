import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { signUpSteps as pageContent } from "../../components/constants";
const SignupSteps = () => {
  return (
    <section className="mt-5">
      <div className="orange-bar">
        <div className="container mt-3">
          {/* <ScrollAnimation animateIn="slideInRight" animateOut="fadeOut"> */}
          <div className="stepsContainer">
            <div className="steps">
              <ScrollAnimation
                animateOnce="true"
                delay={300}
                animateIn="fadeIn"
              >
                <div className="circle d-flex">
                  <h3>{pageContent.heading1}</h3>
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateOnce="true"
                delay={700}
                animateIn="fadeIn"
              >
                <div className="steptext">
                  <p>{pageContent.content1}</p>
                </div>
              </ScrollAnimation>
            </div>


            <div className="steps">
              <ScrollAnimation
                animateOnce="true"
                delay={400}
                animateIn="fadeIn"
              >
                <div className="circle d-flex">
                  <h3>{pageContent.heading2}</h3>
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateOnce="true"
                delay={500}
                animateIn="fadeIn"
              >
                <div className="steptext">
                  <p>{pageContent.content2}</p>
                </div>
              </ScrollAnimation>
            </div>


            <div className="steps">
              <ScrollAnimation
                animateOnce="true"
                delay={500}
                animateIn="fadeIn"
              >
                <div className="circle d-flex">
                  <h3>{pageContent.heading3}</h3>
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateOnce="true"
                delay={700}
                animateIn="fadeIn"
              >
                <div className="steptext">
                  <p>{pageContent.content3}</p>
                </div>
              </ScrollAnimation>
            </div>



            {/* <div className="steps">
              <div className="circle d-flex">
                <h3>{pageContent.heading2}</h3>
              </div>
              <div className="steptext">
                <p>{pageContent.content2}</p>
              </div>
            </div>

            <div className="steps">
              <div className="circle d-flex">
                <h3>{pageContent.heading3}</h3>
              </div>
              <div className="steptext">
                <p>{pageContent.content3}</p>
              </div>
            </div> */}
          </div>
          {/* </ScrollAnimation> */}
        </div>
      </div>
    </section>
  );
};
export default SignupSteps;
