import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "../containers/home/Home";
import ForPartner from "../containers/forPartner/ForPartner";
import AboutUs from "../containers/aboutUs/AboutUs";
import Register from "../containers/register/Register";
import Faq from "../containers/faq/Faq";
import Press from "../containers/press/Press";
import Terms from "../containers/terms/Terms";
import ReferAFriend from "../containers/referFriend/ReferAFriend";
import Privacy from "../containers/privacy/Privacy";
import Career from "../containers/career/Career";
import Layout from "./layout/Layout";
import Refund from "../containers/refund/Refund";
import contactUs from "../containers/contactUs/Contact";
import ScrollToTop from "../functions/functions";

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <Layout>
        <ScrollToTop />
        <Switch>
          <Route path="/refund-policy" component={Refund} />
          <Route path="/refer-a-friend" component={ReferAFriend} />
          <Route path="/terms-and-conditions" component={Terms} />
          <Route path="/faq" component={Faq} />
          <Route path="/career" component={Career} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/press" component={Press} />
          <Route path="/register" component={Register} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/for-resturants" component={ForPartner} />
          <Route path="/contact-us" component={contactUs} />
          {/*<Route path="/" component={Timer} />*/}
          <Route path="/" component={Home} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
