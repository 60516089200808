import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { servicesCard as pageContent } from "../../components/constants/index";

const ServicesCards = () => {
  return (
    <div className="services-cards mt-5">
      {/* <ScrollAnimation animateOnce='true' animateIn="slideInRight" animateOut="fadeOut"> */}
      <div className="d-flex flex-wrap justify-content-around">
        <div className="services-card  mt-4">
          <ScrollAnimation animateOnce="true" delay={200} animateIn="fadeIn">
            <div className="services-card-header">
              <h5>{pageContent.heading1}</h5>
            </div>
            <div className="services-card-body">
              <p> {pageContent.content1} </p>
            </div>
          </ScrollAnimation>
        </div>

        <div className="services-card  mt-4">
          <ScrollAnimation animateOnce="true" delay={300} animateIn="fadeIn">
            <div className="services-card-header">
              <h5> {pageContent.heading2} </h5>
            </div>
            <div className="services-card-body">
              <p> {pageContent.content2} </p>
            </div>
          </ScrollAnimation>
        </div>

        <div className="services-card  mt-4">
          <ScrollAnimation animateOnce="true" delay={400} animateIn="fadeIn">
            <div className="services-card-header">
              <h5> {pageContent.heading3} </h5>
            </div>
            <div className="services-card-body">
              <p> {pageContent.content3} </p>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      {/* </ScrollAnimation> */}
    </div>
  );
};
export default ServicesCards;
