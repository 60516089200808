import React from "react";
import homeBanner from "../../assets/images/home-banner.png";
import { homePage as pageContent } from "../../components/constants/index";
import parse from "html-react-parser";
// import appStoreIco from "../../assets/images/app-store.png";
import googlePlayIco from "../../assets/images/google-play.png";
import HuaweiButton from "../../assets/images/HuaweiButton.png";

import "./home.css";

import ScrollAnimation from "react-animate-on-scroll";

const Home = () => {
  return (
    <div className="page-body homePage">
      <div className="container">
        <div className="row">
          <div className="col-md-5 home-banner justify-content-center">
            <div className="page-text-area">
              <div className="row store-links">
                <div className="col-12 mb-3 mt-3">
                  <ScrollAnimation animateIn="fadeIn">
                    <h1 className="">{pageContent.heading}</h1>
                    {parse(pageContent.content)}
                  </ScrollAnimation>
                </div>
                <div className="col-1" />
                <div className="col-5 icons">
                  <ScrollAnimation
                    animateOnce="true"
                    delay={700}
                    animateIn="fadeIn"
                  >
                    <a
                      href="https://appgallery.huawei.com/app/C107844769"
                      target="_blank"
                    >
                      <img alt="App Store" src={HuaweiButton} />
                    </a>
                  </ScrollAnimation>
                </div>
                <div className="col-5 icons leftMargin">
                  <ScrollAnimation
                    animateOnce="true"
                    delay={400}
                    animateIn="fadeIn"
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.flavry"
                      target="_blank"
                    >
                      <img alt="Google Play" src={googlePlayIco} />
                    </a>
                  </ScrollAnimation>
                </div>
              </div>
              <div className="text-center mt-2">
                <ScrollAnimation
                  animateOnce="true"
                  delay={500}
                  animateIn="fadeIn"
                >
                  {pageContent.downloadApp}
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="col-md-7 home-banner justify-content-end">
            <img src={homeBanner} alt="Home Banner" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
