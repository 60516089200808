import {
  REQUEST_REGISTER,
  SUCCESS_REGISTER,
  FAILURE_REGISTER,
  REQUEST_CONTACT,
  SUCCESS_CONTACT,
  FAILURE_CONTACT,
} from "../constants";

export const submitRegister = (data) => ({
  type: REQUEST_REGISTER,
  payload: data,
});

export const setRegister = (data) => ({
  type: SUCCESS_REGISTER,
  payload: data,
});

export const setErrorRegister = (data) => ({
  type: FAILURE_REGISTER,
  payload: data,
});

export const submitContact = (data) => ({
  type: REQUEST_CONTACT,
  payload: data,
});
export const setContact = (data) => ({
  type: SUCCESS_CONTACT,
  payload: data,
});
export const setErrorContact = (data) => ({
  type: FAILURE_CONTACT,
  payload: data,
});
