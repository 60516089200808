import React from "react";
import {contactArea} from "../../components/constants/index"
import parse from 'html-react-parser';

const ContactUsArea = () => {
  return (
    <section className="mt-5">
      <div className="orange-bar">
        <div className="container">
          {parse(contactArea.content)}
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <button className="btn site-btns">
              <b>{contactArea.heading}</b>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactUsArea;