import React from "react";
import { Link } from "react-router-dom";
import mobileScreens from "../../assets/images/mobile-screens.png";
import {aboutArea} from "../../components/constants/index"
import parse from 'html-react-parser';
const AboutUsArea = () => {
  return (
    <section className="mt-5">
    <div className="container ">
      <div className="row">
        <div className="col-12 text-center">
          <Link to={"/register"}>
            <button className="btn site-btns">{aboutArea.createERes}</button>
          </Link>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-5 col-sm-12">
          <img className="image100"
            src={mobileScreens}
            alt="Mobile Screen"
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="page-text-area">
            <p>Click the above button, fill in your business details and we will get your website ready. After you upload a Menu, we will add it to our search engine and give you a link you can share with your customers.</p><p>
            Or you don’t know what to do? Let us take care of your setup. (This process will take a little longer than online signup). Simply fill the Contact us form and we will get in touch.
          </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};
export default AboutUsArea;