import React from "react";
import { pressPage as pageContent } from "../../components/constants/index";
import parse from 'html-react-parser';
import headerImg from "../../assets/images/press.svg";

const Press = () => {
  return (
    <div className="page-body">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-9 col-xs-12">
            <img className="aboutImage" src={headerImg} alt={pageContent.heading} />
          </div>
        </div>
      </div>

      <section className="heading-cover">
        <div className="container heading-container">
          <h1>{pageContent.heading}</h1>
        </div>
      </section>

      <section className="mt-5">
        <div className="container ">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="pages-text-area">
              {
                    pageContent.press.map((data, index) => {
                      return (<div><h2>{data[0]}</h2> {parse(data[1])} </div>)                    
                  })
              }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Press;