// get a movie info
export const registerReq = async (id) => {
  const respone = await fetch(
    "https://api.themoviedb.org/3/movie/" +
      id +
      "?language=en-US&api_key=06aa50e38281dd9b38543df33f8bab2c"
  );
  const data = respone.json();
  if (respone.status > 400) {
    throw new Error(respone.status);
  }

  return data;
};

// Submit Contact Form
export const contactUs = async (id) => {
  const respone = await fetch(
    "https://api.themoviedb.org/3/movie/" +
      id +
      "?language=en-US&api_key=06aa50e38281dd9b38543df33f8bab2c"
  );
  const data = respone.json();
  if (respone.status > 400) {
    throw new Error(respone.status);
  }

  return data;
};
