import React from "react";
import headerImg from "../../assets/images/about-us.svg";
import { aboutUs } from "../../components/constants/index";
import ScrollAnimation from "react-animate-on-scroll";
import mobileScreens from "../../assets/images/mobile-screens.png";
import "./aboutUs.css";

const AboutUs = () => {
  return (
    <div className="page-body">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-9 col-xs-12">
            <img className="aboutImage" src={headerImg} alt={aboutUs.heading} />
          </div>
        </div>
      </div>

      <section className="heading-cover">
        <div className="container heading-container">
          <h1>{aboutUs.heading}</h1>
        </div>
      </section>

      <section className="mt-5">
        <div className="container ">
          <div className="row mt-5">
            <div className="col-md-6 col-sm-12">
              <div className="page-text-area">
                <p> {aboutUs.content}</p>
                <p> {aboutUs.content1}</p>
                <div>
                  <h5>Some features that makes us different</h5>
                  <p>
                    <b>Limited food options:</b> Flavry contains a vast database
                    of restaurants and their offerings which makes it convenient
                    for users to search for delicious dishes that will suit
                    their personal preferences.
                  </p>
                  <p>
                    <b>
                      Difficulty in finding food that fits within a specific
                      budget:
                    </b>{" "}
                    Flavry assists customers to search for food based on price,
                    discounts, and deals or offers available at restaurants that
                    fit within their budget.
                  </p>
                  <p>
                    <b>Inconvenient location of restaurants:</b> Flavry helps
                    its customers to find restaurants that are within a
                    convenient location based on the distance.
                  </p>
                  <p>
                    <b>Limited cuisine options:</b> Flavry has the option to
                    search for food based on different cuisines. The users can
                    choose from a wide range of available cuisine options,
                    increasing their food-finding options.
                  </p>
                  <p>
                    <b> Inadequate services offered by restaurants:</b> Flavry
                    helps the users to search restaurants that meet their
                    specific needs such as dine-in, wifi, roof sitting or
                    outdoor sitting or open grilling option, delivery, or
                    pickup. Besides this, users can know about the environment
                    or atmosphere available at the restaurant such as Chinese,
                    middle eastern, Turkish, etc.
                  </p>
                  <p>
                    <b>Dietary restrictions:</b> Flavry has an additional
                    feature of discovering food based on ingredients and
                    allergens. Users with dietary restrictions can easily find
                    meals that fit their specific needs.
                  </p>
                  <p>
                    <b> Religious beliefs: </b> Further, Flavry also searches
                    for food based on religious beliefs. It serves users with
                    specific religious dietary needs and helps them discover
                    food that is in accordance with their beliefs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <ScrollAnimation
                animateIn="bounce"
                initiallyVisible={true}
                animateOnce={true}
              >
                <img
                  className="about-image"
                  src={mobileScreens}
                  alt="Mobile Screen"
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
