import React from "react";
import { Link } from "react-router-dom";
import fb from "../../assets/social-icons/fb.svg";
import insta from "../../assets/social-icons/insta.svg";
import linkedIn from "../../assets/social-icons/linkedIn.svg";
import twitter from "../../assets/social-icons/twitter.svg";
import youtube from "../../assets/social-icons/youtube.svg";

const Footer = () => {
  return (
    <section>

      <div className="container mt-5">
        <div className="navLinks">
          <Link to={"/about-us"}>About Us</Link>
          <Link to={"/for-resturants"}>For Restaurants</Link>
            <Link to={"/contact-us"}>Contact us</Link>
{/*          <Link to={"/refer-a-friend"}>Refer a Friend</Link>*/}
     <Link to={"/faq"}>FAQ</Link>
        </div>
        <div className="navLinks">
          <Link to={"/refund-policy"}>Refund Policy</Link>
          <Link to={"/terms-and-conditions"}>Terms & Conditions</Link>
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </div>

        <div className="socialNav mt-3 mb-2">
          <a href="https://www.youtube.com/channel/UCpBAfApAROoTWpWr321jRzQ" target="_blank">
            <img src={youtube} alt="YouTube" />
          </a>
          <a href="https://www.facebook.com/flavryofficial" target="_blank">
            <img src={fb} alt="fb" />
          </a>
          <a href="https://twitter.com/FlavryOy" target="_blank">
            <img src={twitter} alt="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/flavry/?viewAsMember=true" target="_blank">
            <img src={linkedIn} alt="linkedIn" />
          </a>
          <a href="https://www.instagram.com/flavry.offical/" target="_blank">
            <img src={insta} alt="instagram" />
          </a>
        </div>
        <div className="mt-1 mb-1 text-center">
          <p>
            Copyright © 2022 Flavry.com™
            <br />
            All rights reserved
          </p>
        </div>
      </div>
    </section>
  );
};
export default Footer;
