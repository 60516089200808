import React, {useState} from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { Navbar, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <header className="">
      <Navbar
        className="border-bottom fixed-top"
        collapseOnSelect
        expand="md"
        bg="light"
        variant="light"
      >
        <Container>
          <Navbar.Brand href="/">
            <img className="site-logo" src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"        />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav.Item>
              <Nav.Link as={Link} to={"/"} eventKey="1">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to={"/about-us"} eventKey="2">About Us</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to={"/for-resturants"} eventKey="3">For Restaurants</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to={"/contact-us"} eventKey="4">Contact Us</Nav.Link>
            </Nav.Item>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
