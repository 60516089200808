import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { contactPage as pageContent } from "../../components/constants/index";

import "./styles.css";
import { RotatingLines } from "react-loader-spinner";

const ContactUs = () => {
  const [state, setState] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    message: "",
    fullName: "",
  });

  const [showLoader, setShowLoader] = useState(false);

  const { full_name, phone_number, email, message } = state;

  const submitForm = async (event) => {
    event.preventDefault();
    const payload = { ...state };

    if (payload.phone_number === "+92" || payload.phone_number === "") {
      delete payload.phone_number;
    }

    console.log("payload", payload);
    try {
      setShowLoader(true);
      await axios.post(
        "https://api.flavry.com/api/accounts/v1/contactus/send/",
        payload
      );
      toast.success("We have received you form and we will reply you soon.");
    } catch (error) {
      const errors = error.response.data;

      for (const key in errors) {
        toast.error(
          `${errors[key]}. Phone number should be in this form +923333126218`
        );
      }

      // errors.forEach((error) => toast.error(error));
    } finally {
      setShowLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const enableSubmit = () => full_name && email && message && !showLoader;

  return (
    <div className="page-body">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-9 col-xs-12">
            {/* <img className="aboutImage" src={headerImg} alt={pageContent.heading} /> */}
          </div>
        </div>
      </div>

      <section className="heading-cover">
        <div className="container heading-container">
          <h1>{pageContent.heading}</h1>
        </div>
      </section>

      <section className="mt-4">
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <div className="page-text-area">
                <div className="businessDevelopment">
                  <h5>Business Development</h5>
                  <div className="sectionWrapper">
                    <p className="section">Mr. Nabeel Zahoor</p>
                    <p className="section">
                      <a
                        href="mailto:nabeel.zahoor@flavry.com"
                        className="link"
                      >
                        nabeel.zahoor@flavry.com{" "}
                      </a>
                    </p>
                  </div>
                  <div className="sectionWrapper">
                    <p className="section">Mr. Aamir Alvi</p>
                    <p className="section">
                      <a href="mailto:aamir.alvi@flavry.com" className="link">
                        aamir.alvi@flavry.com{" "}
                      </a>
                    </p>
                  </div>
                  <h5 className="customerCare">Customer care</h5>
                  <div className="address">
                    <p>
                      187-B, Block B, PCSIR SOCIETY Phase 1, Canal Road, Lahore.
                    </p>
                    <p className="section">
                      <a href="tel:042-32313800" className="link">
                        042-32313800
                      </a>
                    </p>
                    <p className="section">
                      <a href="mailto:contact@flavry.com" className="link">
                        contact@flavry.com
                      </a>
                    </p>
                  </div>
                </div>
                <p className="contactForm">
                  Or use this form to contact us. Fields with{" "}
                  <span style={{ color: "red" }}>*</span> are necessary
                </p>
                <form onSubmit={(event) => submitForm(event)}>
                  <div className="col-12">
                    <div className="row mt-3">
                      <div className="col-lg-2 col-md-4 col-sm-12">
                        <label>Name</label>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-10">
                        <input
                          type="text"
                          name="full_name"
                          value={full_name}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-2 col-md-4 col-sm-12">
                        <label>Phone Number</label>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-10">
                        <input
                          type="tel"
                          pattern="\+92[0-9]{10}"
                          name="phone_number"
                          value={phone_number}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <span className="instruction">
                          Format: +923455517135
                        </span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-2 col-md-4 col-sm-12">
                        <label>E-mail address</label>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-10">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg-2 col-md-4 col-sm-12">
                        <label>Message</label>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12">
                        <textarea
                          rows="5"
                          name="message"
                          value={message}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg-5 col-md-5 col-sm-10 mb-4">
                        <div className="spinnerContact">
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={showLoader}
                          />
                        </div>
                        <button
                          className="btn site-btns"
                          id="submit"
                          type="submit"
                          disabled={!enableSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
